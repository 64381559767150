export default {
    MENU: 'Menu',
    MY_PROFILE: 'Meu perfil',
    LOGOUT: 'Sair',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Digite seu email...',
    ENTER_PASSWORD: 'Digite sua senha...',
    ERROR: 'Erro',
    SUCCESS: 'Sucesso',
    WARNING: 'Atenção',
    OK: 'OK',
    CANCEL: 'Cancelar',
    CLOSE: 'Fechar',
    CONFIRM: 'Confirmar',
    TYPE: 'Tipo',
    ENTER: 'Entrar',
    DISPLAY_PASSWORD: 'Coloque sua senha',
    DISPLAY_EMAIL: 'Coloque seu email',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    PASSWORD: 'Senha',
    LOGIN_TITLE: 'Portal HIL Cliente',

    INFORMED: 'Informado',
    JUSTIFIED: 'Justificado',
    SERVED: 'Atendida',
    PARTIALLY_SERVED: 'Parcialmente atendida',
    IN_PROGRESS: 'Em andamento',
    ECONOMIC_VIABILITY_STUDY: 'Estudo de viabilidade econômica',
    TECHNICAL_VIABILITY_STUDY: 'Estudo de viabilidade técnica',
    NOT_SERVED: 'Não atendida',

    OLD_PASSWORD: 'Sua atual senha',
    NEW_PASSWORD: 'Sua nova senha',
    CHANGE_PASSWORD: 'Alterar senha de login',
    CHANGE_PASSWORD_TITLE: 'Redefinição de senha',
    CONFIRM_CHANGE_PASSWORD: 'Confirmar e redefinir senha',
    CONFIRM_NEW_PASSWORD: 'Confirmar nova senha',
    CONFIRM_CHANGE: 'Confirmar Troca',
    CONFIRM_DATA_EDITION: 'Confirmar edição de dados',
    CHANGE_USER_DATA: 'Edição de dados do usuário',
    FIRST_NAME: 'Nome',
    FIRST_NAME_MANDATORY: 'Nome é de preenchimento obrigatório!',
    LAST_NAME: 'Sobrenome',
    LAST_NAME_MANDATORY: 'Sobrenome é de preenchimento obrigatório!',
    DISPLAY_NAME: 'Informe o nome',
    DISPLAY_LAST_NAME: 'Coloque o sobrenome',
    DISPLAY_PHONE: 'Informe o telefone',
    PHONE: 'Telefone',
    PHONE_DIGITS_WARNING: 'Deve conter 11 dígitos',
    CPF_DIGITS_WARNING: 'Deve conter 11 dígitos',
    DISPLAY_CNPJ: 'Coloque o CNPJ',
    DISPLAY_PHONE: 'Coloque o telefone',
    DISPLAY_CNAE: 'Coloque o CNAE',
    EDIT_USER_DATA_SUCCESS: 'Dados do usuário editados com sucesso',

    PASSWORD_CONFIRMATION_FAILED: '"Sua nova senha" e "Confirmar nova senha" não são iguais.',
    FILL_ALL_FIELDS_CHANGE_PASSWORD: 'Digite todos os campos para alterar sua senha.',

    PASSWORD_CHANGE_SUCCESS: 'Senha trocada com sucesso!',
    RECOVER_PASSWORD: 'Esqueci minha senha',
    RESET_PASSWORD_SOLICITATION_SUCCESSFULLY:
        'Solicitação feita com sucesso, em breve você receberá um email com mais instruções.',
    DISPLAY_REGISTERED_EMAIL: 'Informe o email cadastrado',
    INVALID_EMAIL: 'Favor inserir um email válido!',
    CHANGE_PASSWORD_TITLE: 'Redefinição de senha',
    CONFIRM_CHANGE_PASSWORD: 'Confirmar e redefinir senha',
    PASSWORD_MUST_BE_EQUAL: 'Senhas precisam ser iguais',
    ENTER_NEW_PASSWORD_SHORT: 'Digite a nova senha...',
    NEW_PASSWORD_SHORT: 'Nova senha',
    DISPLAY_NEW_PASSWORD_SHORT: 'Coloque a nova senha',
    NEW_PASSWORD_CONFIRM: 'Confirmação da nova senha',
    ENTER_NEW_PASSWORD_CONFIRM: 'Digite a confirmação da nova senha...',
    DISPLAY_NEW_PASSWORD_CONFIRM: 'Coloque a confirmação da nova senha',
    YOUR_PASSWORD: 'Sua senha',
    CONFIRM_NEW_PASSWORD: 'Confirmar nova senha',
    CONFIRM_PASSWORD: 'Confirmar senha',
    FILL_ALL_FIELDS_RESET_PASSWORD: 'Digite todos os campos para redefinir a sua senha.',
    FILL_ALL_FIELDS_CHANGE_PASSWORD: 'Digite todos os campos para alterar sua senha.',
    FILL_ALL_FIELDS_CREATE_PASSWORD: 'É necessário informar a senha e a confirmação de senha!',
    PASSWORD_CONFIRMATION_FAILED: '"Sua nova senha" e "Confirmar nova senha" não são iguais.',
    FILL_ALL_FIELDS_CHANGE_EMAIL: 'Digite todos os campos para alterar seu email.',
    LOGIN_ERROR: 'Erro ao efetuar login. ',
    RESET_TOKEN_INVALID:
        'Token inválido. Você deve acessar esta página através do link enviado por email. Caso necessário, solicite uma nova redefinição de senha na página inicial.',

    SHOW_ONLY_NOT_READ: 'Mostrar apenas não lidas',
    SHOW_ALL_NOTIFICATIONS: 'Mostrar todas as notificações',
    SHOW_MORE: 'Mostrar mais',
    NOTIFICATIONS: 'Notificações',
    NOTIFICATION_LIST: 'Lista de notificações',
    REGISTER_NOTIFICATION: 'Cadastrar Notificação',
    NOTIFICATIONS_TITLE: 'Titulo da notificação',
    NOTIFICATIONS_DESCRIPTION: 'Mensagem da notificação',
    NOTIFICATIONS_CREATOR: 'Criador da notificação',
    NOTIFICATION_TYPE: 'Tipo da notificação',
    MARK_ALL_AS_READ: 'Marcar todas como lidas',
    INFO_MARK_ALL_AS_READ: `
        Ao confirmar você irá marcar como lidas todas as notificações vinculadas
        ao seu usuário. Você tem certeza que é isso que você deseja fazer?
    `,
    INFO_NO_NOTIFICATIONS: 'Não há notificações para serem exibidas',
    READED_NOTIFICATION: 'Notificação lida',
    NOT_READED_NOTIFICATION: 'Notificação não lida',
    MARK_AS_READ: 'Marcar como lida',

    REPORTS: 'Relatórios',
    CLIENT: 'Cliente',
    STATUS: 'Status',
    CLIENT_USER_ASSOCIATED: 'Usuário responsável',
    IN_PROGRESS: 'Em Andamento',
    FINISHED: 'Finalizado',
    ADDRESS: 'Endereço',
    CREATED_AT: 'Criado em',
    UPDATED_AT: 'Atualizado em',
    PDF: 'PDF',
    REPORT_DETAILS: 'Detalhes do relatório',
    FOLLOWUP_HISTORIC: 'Histórico de acompanhamentos',
    REPORT: 'Relatório',
    GENERAL_DATA: 'Dados Gerais',
    CLIENT_NAME: 'Razão social',
    CLIENT_CNPJ: 'CNPJ da empresa',
    CLIENT_NUMBER_CNAE: 'Número CNAE da empresa',
    CLIENT_CNAE_DESCRIPTION: 'Descrição da atividade principal',
    ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT:
        'Esse endereço já está sendo utilizado em outro relatório, não será possível selecioná-lo.',
    PARTICIPANTS: 'Participantes',
    NO_PARTICIPANT_SELECTED: 'Nenhum participante selecionado',
    RISKS_IDENTIFICATION: 'Identificação de riscos',
    RISK: 'Risco',
    RECOMMENDATION: 'Recomendação',
    PRIORITY_LEVEL: 'Nível de prioridade',
    AFFECTED_PILLAR: 'Pilar afetado',
    CONTROL_MESURE: 'Medida de controle',
    SEVERITY: 'Severidade',
    FREQUENCY: 'Frequência',
    COVERAGE_SCALE: 'Escala de abrangência',
    DETECTION: 'Detecção',
    RISK_DETAILS: 'Detalhes de risco',
    NO_STRENGHT_SELECTED: 'Nenhum ponto positivo selecionado',
    STRENGHTS: 'Pontos positivos',
    NORMATIVES: 'Normativas',
    NO_ONE_NORMATIVE_FOR_THIS_RISK: 'Nenhuma normativa para esse risco',
    GO_BACK: 'Voltar',
    FOLLOW_UPS: 'Acompanhamentos',
    FOLLOWUP: 'Followup',
    NO_FOLLOW_UPS_INFO: 'Você não tem acompanhamentos para visualizar!',
    SEQUENCE: 'Sequência',
    FINALIZATION_DATE: 'Data de finalização',
    AUTOMATIC_FINALIZATION_DATE: 'Data de finalização automática',

    FOLLOW_UP_DETAIL: 'Detalhes do Acompanhamento',
    EMPTY_01: 'N/A',
    RISKS: 'Riscos',
    NO_STATUS_ALREADY_SELECTED: 'Nenhum status selecionado',
    NO_PREVIOUS_FOLLOWUP: 'Aguardando informação',

    IMAGE: 'Imagem',
    IMAGES: 'Imagens',

    CLIENT_FOLLOWUP: 'Followup empresa',
    FOLLOW_UP: 'Acompanhamento',
    ADD_NEW_ATTACHMENT: 'Adicionar novo anexo',
    REMOVE_ATTACHMENT: 'Remover anexo',
    ATTACHMENT_DESCRIPTION: 'Descrição do anexo',
    MUST_HAVE_ATTACHMENT: 'Anexo deve ser adicionado',
    MUST_HAVE_ATTACHMENT_DESCRIPTION: 'Descrição do anexo deve ser preenchida',
    SUCCESS_ADD_ATTACHMENT: 'Anexo adicionado!',
    FAIL_ADD_ATTACHMENT: 'Anexo inválido!',
    REMOVE_ATTACHMENT_SUCCESS: 'Anexo removido com sucesso',
    ATTACHMENTS: 'Anexos',
    SAVE_ATTACHMENTS: 'Salvar anexos',
    ATTACHMENT_SELECTED_FOR_RISK: 'Anexo selecionado para o risco',
    MAX_ATTACHMENT_SIZE_REACHED: 'O tamanho do arquivo superou o tamanho máximo de {0}mb',
    SELECTED_ATTACHMENTS: 'Arquivos selecionados',

    JUST_IMAGES_ALLOWED: 'É permitido anexar apenas imagens',
    CURRENT_EVIDENCE: 'Evidência atual',
    CURRENT_LOGO: 'Logo atual',

    SELECTED_ADDRESS: 'Endereço selecionado',
    WRITE_A_MESSAGE: 'Digite uma mensagem...',
    SEND_MESSAGE: 'Enviar mensagem',
    SAVE: 'Salvar',

    ATENTION: 'Atenção',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',

    CONFIRM_BEFORE_FINISH_FOLLOWUP:
        'Ao criar um novo acompanhamento, o acompanhamento anterior será finalizado.',
    CLICK_IMAGE_TO_OPEN: 'Clique na imagem para visualizar',

    PROBABILITY_X_GRAVITY: 'Probabilidade x Gravidade',
    RISK_DESCRIPTION: 'Descrição do risco',
    RECOMMENDATION_DESCRIPTION: 'Descrição da recomendação',
    CONTROL_MESURE_DESCRIPTION: 'Descrição da medida de controle',
    SEVERITY_DESCRIPTION: 'Descrição de severidade',
    FREQUENCY_DESCRIPTION: 'Descrição de frequência',
    COVERAGE_SCALE_DESCRIPTION: 'Descrição de escala de abrangência',
    COLOR: 'Cor',
    DETECTION_DESCRIPTION: 'Descrição de detecção',
    REPORT_RISK_DETAILS: 'Detalhes do relatório de risco',

    CLIENT_ADDRESSES: 'Endereços da empresa',
    RETURN_FOLLOWUP: 'Retornar followup',

    WORK_SECTOR: 'Setor',
    LOCATION: 'Localização',

    WHO_SEND: 'Quem enviou',
    SEND_DATE: 'Data de envio',
    SELECT_MESSAGE: 'Selecionar mensagem',
    INFO_SELECT_MESSAGE: 'Deseja selecionar essa mensagem de retorno do followup?',
    SELECT_ATTACHMENT: 'Selecione um anexo',
    NEW_MESSAGE: 'Nova mensagem',
    CREATE_MESSAGE: 'Criar mensagem',
    SELECT_ATTACHMENT_TO_REPORT: 'Selecionar um anexo',
    SELECT_ATTACHMENT_FOR_MESSAGE: 'Adicione um anexo para está mensagem',
    MESSAGE: 'Mensagem',
    DISPLAY_MESSAGE: 'Informe a mensagem',
    FOLLOWUP_SEQUENCE: '{0} -- {1} - Iniciado em: {2} - Finalização automática em: {3}',
    FUP_CLIENT_ADDRESS: '{0}/{1}, Bairro: {2}, Rua: {3}, nº: {4}',

    ADD_NEW_ADDRESS: 'Adicionar novo endereço',
    RECOMMENDATION_DIMENSIONS: 'Dimensão recomendada até 800x800',
    RECOMMENDATION_SIZE: 'Tamanho recomendad0 até 250kb',

    OBSERVATIONS: 'Observações',
    EVIDENCE_DESCRIPTION: 'Descrição da envidência',
    TECHNICAL_MANAGER: 'Responsável técnico',

    PRO_ATI: 'PRO.ATI.{0}',
    ERROR_MISSING_DESCRIPTION: 'Escreva uma mensagem',
    LAST_MESSAGE_SENT: 'Última mensagem enviada',
    TAG_RETURN_FOLLOW_UP_REPORT_RISK: 'Falta de Informação',
    DOCUMENT: 'Documento',

    INFORMED_HIL: 'Informado HIL',
    WAITING_FOR_INFORMATION: 'Aguardando informação',
    FOLLOWUP_FORMATTED_SEQUENCE: 'Follow-Up {0}',

    ACTIVE_PROTECTION: 'Proteção de Ativos',
    ACTIVE_PROTECTION_ACRONYM: 'PRO.ATI.',
    AUDIT_DOCUMENTS: 'Auditoria Documentos',
    AUDIT_DOCUMENTS_ACRONYM: 'AUD.DOC.',

    RECOMMENDATION_NUMBER: 'Nº Recomendação',
    PRIORITY: 'Prioridade',

    FILTER_NOTIFICATIONS: 'Filtrar notificações',
    SELECT_ONE_CLIENT: 'Selecione uma empresa',

    NOTIFICATION_TITLE_FOLLOWUP_REPORT_RISK_STATUS_CHANGED: 'Status do risco do followup alterado',

    INSURANCE_COMPANY: 'Solicitante',
    INSURANCE_COMPANIES: 'Solicitantes',

    BUILD_DASHBOARD: 'Montar gráficos',
    DASHBOARD: 'Dashboard',
    
    GRPH01_TITLE: 'GERENCIAMENTO DE RISCO',
    GRPH02_TITLE: 'STATUS',
    GRPH03_TITLE: 'PRIORIDADE',
    GRPH04_TITLE: 'AUDITORIA DE DOCUMENTOS',

    CLIENTS: 'Empresas',
    STREET: 'Rua',
    PRIORITIES: 'Prioridades',

    SELECT_CLIENTS: 'Selecione empresas',
    SELECT_ADDRESS: 'Selecione endereços',
    SELECT_PRIORITIES: 'Selecione prioridades',
    SELECT_RISK_TYPES: 'Selecione tipo dos riscos',
    SELECT_RISK_STATUS: 'Selecione status dos riscos',
    SELECT_INSURANCE_COMPANY: 'Selecione solicitantes',
}
